define("discourse/plugins/discourse-pfaffmanager/discourse/controllers/pfaffmanager-servers-show", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    unsubscribe() {
      this.messageBus.unsubscribe("/pfaffmanager-server-status/*");
    },
    subscribe() {
      this.unsubscribe();
      const server = this.server;
      this.messageBus.subscribe(
      // `/pfaffmanager-server-status/${server.id}`, data => {
      `/pfaffmanager-server-status/${server.id}`, data => {
        server.setProperties({
          request_created_at: data.request_created_at,
          request_status: data.request_status,
          request_status_updated_at: data.request_status_updated_at,
          server_status_json: data.server_status_json,
          have_do_api_key: data.have_do_api_key,
          have_mg_api_key: data.have_mg_api_key,
          droplet_size: data.droplet_size,
          request_result: data.request_result,
          dashboard_general: data.dashboard_general,
          active: data.active,
          expected_duration: data.expected_duration
        });
      });
    }
  });
});