define("discourse/plugins/discourse-pfaffmanager/discourse/routes/pfaffmanager-servers-index", ["exports", "@ember/array", "discourse/lib/keyboard-shortcuts", "discourse/routes/discourse", "discourse/plugins/discourse-pfaffmanager/discourse/models/server"], function (_exports, _array, _keyboardShortcuts, _discourse, _server) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _server.default.listServers();
    },
    setupController(controller, model) {
      controller.set("servers", (0, _array.A)(model.servers));
      controller.set("install_types", (0, _array.A)(model.install_types));
      controller.set("groups", (0, _array.A)(model.groups));
      controller.set("product_hash", (0, _array.A)(model.product_hash));
      const payingGroups = model.groups.filter(hash => !hash.name.toLowerCase().includes("free"));
      controller.set("haveGroups", payingGroups.length > 0);
      let digit = 0;
      model.groups.filter(Boolean).map(group => {
        digit += 1;
        _keyboardShortcuts.default.addShortcut(`g ${digit}`, null, {
          path: `/pfaffmanager/group/${group.name}`
        });
      });
    }
  });
});