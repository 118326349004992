define("discourse/plugins/discourse-pfaffmanager/discourse/routes/pfaffmanager-actions", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    controllerName: "actions",
    renderTemplate() {
      this.render("actions");
    }
  });
});