define("discourse/plugins/discourse-pfaffmanager/discourse/controllers/pfaffmanager-servers-edit", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@ember/template", "discourse/models/group", "discourse/models/user", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-pfaffmanager/discourse/models/server"], function (_exports, _controller, _object, _service, _template, _group, _user, _decorators, _I18n, _server) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import discourseComputed from "discourse-common/utils/decorators";
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    router: (0, _service.inject)(),
    // TODO: This returns nothing and gets called in the template; there must be a better way
    // @discourseComputed("server.delete_plugins", "server.plugins")
    // countPluginsRemoved(deletePlugins, pluginArray) {
    //   let count = 0;
    //   const deleteMe = pluginArray.map((plugin) => {
    //     plugin.deletePlugin = deletePlugins.includes(plugin.url);
    //   });
    // },
    cantSave: false,
    envHelpOn: false,
    currentUser: _user.default.current(),
    serverGroups: _user.default.current().groups.filter(g => g.owner),
    extraVarsSmtp: {
      helpLocale: "pfaffmanager.server.env_smtp_help",
      valArray: ["DISCOURSE_SMTP_ADDRESS: missing", "DISCOURSE_SMTP_PORT: 2525", "DISCOURSE_SMTP_USER_NAME: missing", "DISCOURSE_SMTP_ENABLE_START_TLS: true", "DISCOURSE_NOTIFICATION_EMAIL: noreply@hostname"]
    },
    extraVarsS3Backups: {
      helpLocale: "pfaffmanager.server.env_s3_backups_help",
      valArray: ["DISCOURSE_S3_ACCESS_KEY_ID: 'ENTER YOUR KEY IN THESE QUOTES'", "DISCOURSE_S3_SECRET_ACCESS_KEY: 'ENTER YOUR KEY IN THESE QUOTES'", "DISCOURSE_S3_REGION: 'us-east-1'", "DISCOURSE_S3_BACKUP_BUCKET: 'ENTER BUCKET NAME IN THESE QUOTES'", "DISCOURSE_BACKUP_LOCATION: 's3'", "DISCOURSE_S3_ENDPOINT: https://nyc3.digitaloceanspaces.com"]
    },
    extraStaging: {
      helpLocale: "pfaffmanager.server.env_staging_help",
      valArray: ["DISCOURSE_ENABLE_RESTORE: true", "DISCOURSE_LOGIN_REQUIRED: true", "DISCOURSE_AUTOMATIC_BACKUPS_ENABLED: false", "DISCOURSE_S3_BACKUP_BUCKET: 'MISSING-backup'", "DISCOURSE_BACKUP_LOCATION: 's3'", "DISCOURSE_S3_DISABLE_CLEANUP: true", "DISCOURSE_CLEAN_UP_UPLOADS: false", "DISCOURSE_S3_REGION: 'your_s3_region'", "DISCOURSE_DISABLE_EMAILS: non-staff"]
    },
    extraVarsDrupalAuth: {
      helpLocale: "pfaffmanager.server.env_discourse_connect_help",
      valArray: ["DISCOURSE_DISCOURSE_CONNECT_URL: 'https://www.yourforum.com/discourse_sso'", "DISCOURSE_DISCOURSE_CONNECT_SECRET: 'ENTER YOUR SECRET KEY IN THESE QUOTES'", "DISCOURSE_DISCOURSE_CONNECT_ALLOWS_ALL_RETURN_PATHS: 'true'", "DISCOURSE_AUTH_SKIP_CREATE_CONFIRM: 'true'", "DISCOURSE_AUTH_OVERRIDES_EMAIL: 'true'", "DISCOURSE_ENABLE_DISCOURSE_CONNECT: 'true'"]
    },
    belongsToMyPrimaryGroup(serverGroupId, myGroupId) {
      return serverGroupId === myGroupId;
    },
    addToPrimaryGroupButton(group) {
      return _I18n.default.t("pfaffmanager.server.move_to_primary_group", {
        group
      });
    },
    removeFromPrimaryGroupButton(group) {
      return _I18n.default.t("pfaffmanager.server.remove_from_primary_group", {
        group
      });
    },
    groupFinder(term) {
      const g = _group.default.findAll({
        term,
        ignore_automatic: false
      });
      return g;
    },
    showEnvHelp() {
      this.set("envHelpOn", !this.envHelpOn);
      const help = (0, _template.htmlSafe)(_I18n.default.t("pfaffmanager.server.env_help_help"));
      this.set("serverEditHelp", this.envHelpOn ? help : "");
    },
    addExtraVars(data) {
      const valArray = data.valArray;
      const helpLocale = data.helpLocale;
      this.set("envHelpOn", true);
      if (!this.server.discourse_env || this.server.discourse_env.length === 0) {
        this.set("server.discourse_env", []);
      }
      const env = this.server.discourse_env;
      valArray.forEach(value => {
        env.pushObject(value);
      });
      // this.set("server.discourse_env", env);
      const help = (0, _template.htmlSafe)(_I18n.default.t(helpLocale));
      this.set("serverEditHelp", help);
      return;
    },
    updateOwner(selected) {
      this.set("server.username", selected[selected.length - 1]);
    },
    moveServerToPrimaryGroup() {
      this.set("loading", true);
      this.set("server.group_id", this.currentUser.primary_group_id);
      _server.default.updateServer(this.server).then(result => {
        if (result.errors) {
          window.console.log("got an error on update ", result.errors);
        } else {
          this.set("server", _server.default.create(result.server));
        }
      }).finally(() => this.set("loading", false));
    },
    removeServerFromPrimaryGroup() {
      this.set("loading", true);
      this.set("server.group_id", null);
      _server.default.updateServer(this.server).then(result => {
        if (result.errors) {
          window.console.log("got an error on update ", result.errors);
        } else {
          this.set("server", _server.default.create(result.server));
        }
      }).finally(() => this.set("loading", false));
    },
    saveServer() {
      this.set("cantSave", true);
      this.set("loading", true);
      // loop through plugins and populate delete_plugins
      let pluginsToDelete;
      if (this.server.plugins) {
        pluginsToDelete = this.server.plugins.filter(p => {
          return p.deletePlugin;
        });
      } else {
        pluginsToDelete = [];
      }
      const deleteList = pluginsToDelete.map(p => p.url);
      this.set("server.delete_plugins", deleteList);
      _server.default.updateServer(this.server).then(result => {
        if (result.errors) {
          window.console.log("got an error on update ", result.errors);
        } else {
          this.set("server", _server.default.create(result.server));
          this.set("cantSave", false);
        }
      }).finally(() => {
        this.set("loading", false);
        this.router.transitionTo("pfaffmanager.servers.show", this.server.id);
      });
    }
  }, [["method", "belongsToMyPrimaryGroup", [(0, _decorators.default)("server.group_id", "currentUser.primary_group_id", "currentUser.primary_group_name")]], ["method", "addToPrimaryGroupButton", [(0, _decorators.default)("currentUser.primary_group_name")]], ["method", "removeFromPrimaryGroupButton", [(0, _decorators.default)("currentUser.primary_group_name")]], ["method", "showEnvHelp", [_object.action]], ["method", "addExtraVars", [_object.action]], ["method", "updateOwner", [_object.action]], ["method", "moveServerToPrimaryGroup", [_object.action]], ["method", "removeServerFromPrimaryGroup", [_object.action]], ["method", "saveServer", [_object.action]]]));
});