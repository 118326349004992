define("discourse/plugins/discourse-pfaffmanager/discourse/components/server-link", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // this isn't used but kept as an example. . .

  class ServerLink extends _component.default {
    // Required argument for the URL
    url = null;
    // Optional argument for the link text
    text = "asdf";
    // click() {
    //   console.log("ServerLink clicked!", this);
    // }
    // Template for the component
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        LINK!
        <a href={{this.url}}>{{this.text}}</a>
      
    */
    {
      "id": "9yTxo0QF",
      "block": "[[[1,\"\\n    LINK!\\n    \"],[10,3],[15,6,[30,0,[\"url\"]]],[12],[1,[30,0,[\"text\"]]],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-pfaffmanager/discourse/components/server-link.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ServerLink;
});