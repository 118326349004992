define("discourse/plugins/discourse-pfaffmanager/discourse/routes/user-servers-index", ["exports", "@ember/array", "discourse/routes/discourse", "discourse/plugins/discourse-pfaffmanager/discourse/models/server"], function (_exports, _array, _discourse, _server) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _server.default.listServers();
    },
    setupController(controller, model) {
      controller.set("servers", (0, _array.A)(model.servers));
    }
  });
});