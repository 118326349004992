define("discourse/plugins/discourse-pfaffmanager/discourse/templates/githubs-index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    github controller-index.hbs
  */
  {
    "id": "+DLdz32R",
    "block": "[[[1,\"github controller-index.hbs\"]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-pfaffmanager/discourse/templates/githubs-index.hbs",
    "isStrictMode": false
  });
});