define("discourse/plugins/discourse-pfaffmanager/discourse/routes/pfaffmanager-group-show", ["exports", "@ember/array", "discourse/routes/discourse", "discourse/plugins/discourse-pfaffmanager/discourse/models/server"], function (_exports, _array, _discourse, _server) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // console.log("group show!");
  var _default = _exports.default = _discourse.default.extend({
    model(params) {
      return _server.default.listGroupServers(params.groupName);
    },
    setupController(controller, model) {
      model.servers.then(result => {
        controller.set("servers", (0, _array.A)(result.servers));
        controller.set("groupName", model.groupName);
        controller.set("groups", (0, _array.A)(result.groups));
        controller.set("haveGroups", result.groups.length > 0);
      });
    }
  });
});