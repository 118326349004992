define("discourse/plugins/discourse-pfaffmanager/discourse/controllers/pfaffmanager-group-show", ["exports", "@ember/controller", "@ember/object", "discourse/plugins/discourse-pfaffmanager/discourse/models/server"], function (_exports, _controller, _object, _server) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    actions: {
      dropletCreate() {
        _server.default.createServerForUser(this.model).then(result => {
          if (result.errors) {
            // console.log("Errors: ", result.errors);
          } else {
            // console.log("Success");
          }
        });
      },
      createServer() {
        const server = {
          user_id: this.currentUser.id
          // TODO: add group ID here too
        };
        _server.default.createServer(server).then(result => {
          if (result.server) {
            this.get("servers").pushObject(_object.default.create(result.server));
            window.location.reload(true);
          }
        });
      }
    }
  });
});