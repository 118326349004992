define("discourse/plugins/discourse-pfaffmanager/discourse/routes/pfaffmanager-servers", ["exports", "discourse/routes/discourse", "discourse-common/lib/get-url", "discourse/plugins/discourse-pfaffmanager/discourse/models/server"], function (_exports, _discourse, _getUrl, _server) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    activate() {
      this._super(...arguments);
      this.messageBus.subscribe(`/pfaffmanager-server-status/${self.id}`, () => this.server_message);
    },
    deactivate() {
      this.messageBus.unsubscribe(`/pfaffmanager-server-status/${self.id}`);
    },
    actions: {
      deleteServer(server) {
        _server.default.delete(server.id);
        window.location = (0, _getUrl.default)("/pfaffmanager/servers");
      }
    }
  });
});