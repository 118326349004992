define("discourse/plugins/discourse-pfaffmanager/discourse/routes/pfaffmanager-find-show", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-pfaffmanager/discourse/models/server"], function (_exports, _discourse, _server) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // console.log("group show!");
  var _default = _exports.default = _discourse.default.extend({
    model(params) {
      window.console.log("route extend fs", params);
      return _server.default.listFindServers(params.s);
    },
    setupController(controller, model) {
      model.servers.then(result => {
        controller.set("servers", result.servers);
      });
    }
  });
});