define("discourse/plugins/discourse-pfaffmanager/discourse/controllers/pfaffmanager-servers-show-item", ["exports", "@ember/controller", "discourse/mixins/buffered-content", "discourse/plugins/discourse-pfaffmanager/discourse/models/server"], function (_exports, _controller, _bufferedContent, _server) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend((0, _bufferedContent.bufferedProperty)("server"), {
    unsubscribe() {
      this.messageBus.unsubscribe("/pfaffmanager-server-status/*");
    },
    subscribe() {
      this.unsubscribe();
      const server = this.server;
      this.messageBus.subscribe(`/pfaffmanager-server-status/${server.id}`, data => {
        server.setProperties({
          request: data.request,
          request_created_at: data.request_created_at,
          request_status: data.request_status,
          request_status_updated_at: data.request_status_updated_at,
          server_status_json: data.server_status_json,
          dashboard_general: data.dashboard_general,
          request_result: data.request_result,
          have_do_api_key: data.have_do_api_key,
          have_mg_api_key: data.have_mg_api_key,
          droplet_size: data.droplet_size,
          active: data.active,
          expected_duration: data.expected_duration
        });
      });
    },
    actions: {
      dropletCreate() {
        _server.default.dropletCreate(this.server);
      },
      upgradeServer() {
        _server.default.upgradeServer(this.server).then(result => {
          // eslint-disable-next-line no-console
          console.log(result);
        });
      },
      updateServer() {
        _server.default.updateServer(this.server).then(result => {
          if (result.errors) {
            // eslint-disable-next-line no-console
            console.log("Errors: ", result.errors);
          } else if (result.success) {
            this.set("server", _server.default.create(result.server));
          }
        });
      }
    }
  });
});