define("discourse/plugins/discourse-pfaffmanager/discourse/routes/pfaffmanager-servers-edit", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-pfaffmanager/discourse/models/server"], function (_exports, _discourse, _server) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model(params) {
      const server = _server.default.edit(params.id).then(result => {
        return result;
      });
      return server;
    },
    setupController(controller, model) {
      controller.set("server", model);
    }
  });
});