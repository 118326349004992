define("discourse/plugins/discourse-pfaffmanager/discourse/routes/pfaffmanager-groups-show", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    // console.log("pfaffmanager-servers-group-index-route")
    activate() {
      this._super(...arguments);
      this.messageBus.subscribe(`/pfaffmanager-server-status/${self.id}`, () => this.server_message);
    },
    deactivate() {
      this.messageBus.unsubscribe(`/pfaffmanager-server-status/${self.id}`);
    }
  });
});