define("discourse/plugins/discourse-pfaffmanager/discourse/pfaffmanager-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("pfaffmanager", function () {
      this.route("actions", function () {
        this.route("show", {
          path: "/:id"
        });
      });
      this.route("servers", function () {
        this.route("show", {
          path: "/:id"
        });
      });
      this.route("servers", function () {
        this.route("edit", {
          path: "/:id/edit"
        });
      });
      this.route("group", function () {
        this.route("show", {
          path: "/:groupName"
        });
      });
      this.route("find", function () {
        this.route("show", {
          path: "/:s"
        });
      });
      this.route("servers/group", function () {
        this.route("show", {
          path: "/servers/:groupName"
        });
      });
      this.route("githubs", function () {
        this.route("show", {
          path: "/:id"
        });
      });
      this.route("ssh_key", function () {
        this.route("show", {
          path: "/:id"
        });
      });
      this.route("api_key", function () {
        this.route("show", {
          path: "/:id"
        });
      });
      this.route("upgrade", function () {
        this.route("show", {
          path: "/:id"
        });
      });
    });
  }
});