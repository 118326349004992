define("discourse/plugins/discourse-pfaffmanager/discourse/initializers/setup-pfaffmanager-servers", ["exports", "discourse/lib/keyboard-shortcuts", "discourse/lib/plugin-api", "discourse/lib/utilities", "discourse/models/user", "discourse/plugins/discourse-pfaffmanager/discourse/models/server", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _keyboardShortcuts, _pluginApi, _utilities, _user, _server, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import I18n from "I18n";
  // import ServerLink from "../components/server-link";
  var _default = _exports.default = {
    afterModel: model => model.reload(),
    name: "setup-pfaffmanager-servers",
    //  initialize(container) {
    initialize() {
      (0, _pluginApi.withPluginApi)("0.11.4", api => {
        _keyboardShortcuts.default.addShortcut("g s", null, {
          path: "/pfaffmanager/servers"
        });
        const currentUser = _user.default.current();
        // const siteSettings = container.lookup("site-settings:main");
        let servers = [];
        if (currentUser && currentUser.servers !== undefined) {
          servers = currentUser.servers.sort((b, a) => {
            return ("" + a.updated_at).localeCompare(b.updated_at);
          });
        }
        if (servers.length === 1) {
          (0, _utilities.setDefaultHomepage)(`pfaffmanager/servers/${servers[0].id}`);
        } else if (currentUser) {
          (0, _utilities.setDefaultHomepage)("pfaffmanager/servers");
        }
        if (currentUser !== undefined) {
          const maxServerLinks = 3;
          servers.length = servers.length > maxServerLinks ? maxServerLinks : servers.length;
          if (servers.length > 0) {
            let digit = 0;
            servers.filter(Boolean).map(server => {
              digit += 1;
              _keyboardShortcuts.default.addShortcut(`s ${digit}`, null, {
                path: `/pfaffmanager/servers/${server.id}`
              });
            });
            servers.filter(Boolean).map(server => {
              const linkHref = `/pfaffmanager/servers/${server.id}`;
              const linkTitle = `click to configure server ${server.id}`;
              let host = String(server.hostname);
              const linkText = host.replace(/www.|community.|forums?.|talk.|discourse./, "");
              const serverLink = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
              /*
                
                            <li class="headerLink">
                              <a class="btn-flat" href={{linkHref}} title={{linkTitle}}>
                                {{linkText}}
                              </a>
                            </li>
                          
              */
              {
                "id": "LwQqNV5B",
                "block": "[[[1,\"\\n              \"],[10,\"li\"],[14,0,\"headerLink\"],[12],[1,\"\\n                \"],[10,3],[14,0,\"btn-flat\"],[15,6,[32,0]],[15,\"title\",[32,1]],[12],[1,\"\\n                  \"],[1,[32,2]],[1,\"\\n                \"],[13],[1,\"\\n              \"],[13],[1,\"\\n            \"]],[],false,[]]",
                "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-pfaffmanager/discourse/initializers/setup-pfaffmanager-servers.js",
                "scope": () => [linkHref, linkTitle, linkText],
                "isStrictMode": true
              }), (0, _templateOnly.default)(undefined, "setup-pfaffmanager-servers:serverLink"));
              const beforeIcon = ["chat", "search", "hamburger", "user-menu"];
              api.headerIcons.add(host, serverLink, {
                before: beforeIcon
              });
            });
          }
        }
        api.modifyClass("controller:subscribe-show", {
          pluginId: "pfaffmanager",
          async _advanceSuccessfulTransaction() {
            this.alert("plans.success");
            this.set("loading", false);
            servers = await _server.default.listServers().then(() => {
              if (currentUser && currentUser.servers !== undefined) {
                servers = currentUser.servers.sort((b, a) => {
                  return ("" + a.updated_at).localeCompare(b.updated_at);
                });
                if (servers.length > 0) {
                  this.transitionToRoute("pfaffmanager.servers.show", servers[0].id);
                } else {
                  this.transitionToRoute("pfaffmanager.servers.index");
                }
              }
            });
          }
        });
      });
    }
  };
});